import { defineStore } from 'pinia';

export const SELECTABLE_CONTAINER_STORE_ID = 'Selectable_store';

type SelectableContainerStoreType = {
	selectableContainers: Map<string, Array<string>>;
	tracked: boolean;
};

export const SelectableContainerStore = defineStore(SELECTABLE_CONTAINER_STORE_ID, {
	state: (): SelectableContainerStoreType => ({
		selectableContainers: new Map<string, Array<string>>(),
		tracked: false,
	}),
	actions: {
		add(container: string, productId: string) {
			const products = this.selectableContainers.get(container);
			if (!products) {
				const p = [productId];
				this.selectableContainers.set(container, p);
			} else {
				products?.push(productId);
			}
		},
		remove(container: string, productId: string) {
			const products = this.selectableContainers.get(container);
			if (!products) {
				// do nothing
			} else {
				const prods = products.filter((elem) => elem !== productId);
				this.selectableContainers.set(container, prods);
			}
		},
	},
});
