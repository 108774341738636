<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="!isMenuActive && isBannerVisible"
		:class="[
			'notification-block',
			model.backgroundColor?.cssClass,
			`notification-block--${model.notificationHeight}`,
		]"
	>
		<div class="notification-block__content">
			<div class="notification-block__icon">
				<lazy-image
					v-if="model.icon"
					:image="model.icon"
					:fixedHeight="24"
				/>
			</div>
			<div
				v-if="model.richText"
				class="notification-block__text rich-text"
				:class="{ 'notification-block__text--red': model.backgroundColor?.cssClass === 'bg-red text-light' }"
				v-html="model.richText"
			></div>
			<div class="notification-block__close-icon">
				<lazy-image
					v-if="model.closeIcon"
					:image="model.closeIcon"
					:fixedHeight="24"
					@click="closeNotification"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useAppStateStore } from '@/stores/appState';
	import { IHeaderBlock, INotificationBlock } from '@/types/layout';
	import { computed, ref, watch } from 'vue';
	import { useRoute } from 'vue-router';

	const route = useRoute();
	const appStateStore = useAppStateStore();

	const props = defineProps<{
		model: INotificationBlock;
		headerModel: IHeaderBlock;
		storybook?: boolean;
	}>();

	const isBannerVisible = ref(true);
	const previousPageType = ref('');

	watch(route, () => {
		let currentPageType;
		if (route.path.startsWith(props.headerModel.businessPageUrl.url || '/erhverv/')) {
			currentPageType = 'business';
		} else if (route.path.startsWith(props.headerModel.agriculturePageUrl?.url || '/landbrug/')) {
			currentPageType = 'agriculture';
		} else {
			currentPageType = 'private';
		}

		if (previousPageType.value !== currentPageType) {
			isBannerVisible.value = true;
			previousPageType.value = currentPageType;
		}
	});

	const closeNotification = () => {
		isBannerVisible.value = false;
	};

	const isMenuActive = computed(() => {
		return appStateStore.isMenuOpen;
	});
</script>

<style lang="scss">
	@import './LayoutNotification.module.scss';
</style>
