<template>
	<div class="video">
		<template v-if="model.thumbnailImage?.url">
			<div
				class="video__preview"
				:class="{ 'video__preview--hide': videoPlaying }"
			>
				<lazy-image
					:image="model.thumbnailImage"
					:ratio="playerRatio"
				/>
			</div>
			<button
				id="play-button"
				class="video__play-button btn btn--xxl btn--cta-animated btn--secondary"
				:class="{
					'btn--sm-pad': !model.durationOverride,
					'video__play-button--hide': videoPlaying && !videoEnded,
				}"
				type="button"
				@click="playVideo"
			>
				<smart-svg :url="IconSystemPlay" />
				{{ model.durationOverride }}
			</button>
			<iframe
				id="player"
				class="video__embedded"
				:width="playerWidth"
				:height="playerHeight"
				:src="videoSource"
				:style="playerRatioStyle"
				frameborder="0"
				allow="encrypted-media"
				allowFullScreen="allowFullScreen"
			/>
		</template>
	</div>
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue';
	import { IYoutubeBlock } from '@/types/block';
	import IconSystemPlay from '@/assets/svg/icon_system_Play.svg';
	import { dataLayer } from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: IYoutubeBlock;
	}>();

	const videoPlaying = ref(false);
	const videoEnded = ref(false);
	const videoDuration = ref('00:00');

	const playerWidth = computed(() => {
		return props.model.playerWidth ?? 1600;
	});

	const playerHeight = computed(() => {
		return props.model.playerHeight ?? 900;
	});

	const playerRatio = computed(() => {
		return `${playerWidth.value}/${playerHeight.value}`;
	});

	const playerRatioStyle = computed(() => {
		return `aspect-ratio: ${playerRatio.value}`;
	});

	const originUrl = 'https://www.almbrand.dk' || 'https://alpha.almbrand.dk';

	const videoSource = computed(() => {
		return `https://www.youtube-nocookie.com/embed/${props.model.youtubeId}?rel=0&enablejsapi=1&mute=1&originUrl=${originUrl}`;
	});

	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';
	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

	let player: any;

	(window as any).onYouTubeIframeAPIReady = function () {
		player = new (window as any).YT.Player('player', {
			playerVars: {
				playsinline: 1,
			},
			events: {
				onStateChange: onStateChange,
			},
		});
	};

	const playVideo = () => {
		player.playVideo();
		videoPlaying.value = true;

		const getVideoDuration = player.getDuration();
		const minutes = Math.floor(getVideoDuration / 60);
		const seconds = Math.floor(getVideoDuration - minutes * 60);
		videoDuration.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

		dataLayer.push({
			event: 'trackVideoStart',
			videoProvider: 'Youtube',
			videoDuration: videoDuration.value,
			videoUrl: videoSource.value,
		});
	};

	const onStateChange = (event: any) => {
		if (event.data === (window as any).YT.PlayerState.ENDED) {
			videoEnded.value = true;

			dataLayer.push({
				event: 'trackVideoComplete',
				videoProvider: 'Youtube',
				videoDuration: videoDuration.value,
				videoUrl: videoSource.value,
			});
		}

		if (event.data === (window as any).YT.PlayerState.PLAYING) {
			videoPlaying.value = true;
			videoEnded.value = false;
		}
	};
</script>

<style lang="scss">
	@import './YoutubeBlock.module.scss';
</style>
