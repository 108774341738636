<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="paragraph"
		:class="[`p-${paddingNum()}`, model.backgroundColor?.cssClass]"
	>
		<countdown
			v-if="model.countdownEndDate && model.countdownPositionTop"
			:title="model.countdownTitle"
			:endDate="model.countdownEndDate"
			:expiredText="model.countdownExpiredText"
			:positionTop="model.countdownPositionTop"
		/>
		<div class="paragraph__title rich-text">
			<template v-if="model.header">
				<h2 class="text-center">{{ model.header }}</h2>
				<hr />
			</template>
		</div>
		<div
			v-if="model.richText"
			class="paragraph__content rich-text"
			:class="model.contentText && !isExpandableActive ? 'add-fade' : ''"
			v-html="model.richText"
		></div>
		<template v-if="model.contentText">
			<transition name="extend">
				<div
					v-if="model.richText"
					v-show="isExpandableActive"
					class="rich-text"
					v-html="model.contentText"
				></div>
			</transition>
			<div
				class="paragraph__cut"
				:class="{ 'paragraph__cut--active': isExpandableActive }"
			>
				<p class="paragraph__cut-label">Læs mere</p>
				<div class="paragraph__cut-button">
					<button
						class="btn btn--sm-pad btn--secondary"
						@click="toggleExpandable"
					>
						<smart-svg
							:url="IconSystemChevronDown"
							:width="20"
							:height="20"
						></smart-svg>
					</button>
				</div>
			</div>
		</template>
		<countdown
			v-if="model.countdownEndDate && !model.countdownPositionTop"
			:title="model.countdownTitle"
			:endDate="model.countdownEndDate"
			:expiredText="model.countdownExpiredText"
		/>
		<div
			v-if="model.buttons"
			class="center-text"
			:class="ctaMarginClass"
		>
			<template
				v-for="(button, i) in model.buttons"
				:key="i"
			>
				<smart-link
					v-if="button.link.url"
					:url="button.link.url"
					class="btn"
					:class="ctaClasses(button)"
					:target="button.target"
					:title="button.title"
					:gtmLabel="button.gtmLabel"
					:gtmAction="button.gtmAction"
					:useVueRoute="button.link.useVueRoute"
					:trackingData="gtmNavigationalTracking(button.title, 'call-to-action', button.link.url)"
					:promotionalTracking="promotionData"
				>
					{{ button.title }}
					<smart-svg
						v-if="button.target === '_blank'"
						class="footer__nav-link-icon"
						:url="IconLinkExternal"
					>
					</smart-svg>
				</smart-link>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, onMounted, onUpdated, ref } from 'vue';
	import { IParagraphBlock, ICta } from '@/types/block';
	import IconSystemChevronDown from '@/assets/svg/icon_system_Chevron_down.svg';
	import IconLinkExternal from '@/assets/svg/icon_system_external_link.svg';
	import {
		dataLayer,
		gtmNavigationalTracking,
		gtmTrackSelectPromotion,
		gtmTrackViewPromotion,
		handleGtmHeader,
	} from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: IParagraphBlock;
		spacing?: [string, string];
	}>();

	const isExpandableActive = ref(false);

	const toggleExpandable = () => {
		isExpandableActive.value = !isExpandableActive.value;
	};

	const paddingNum = () => {
		if (props.model?.padding) {
			return props.model.padding;
		}
		return '0';
	};

	const ctaMarginClass = computed(() => {
		return [
			props.model.ctaMarginTop !== 'default' ? `mt-${props.model.ctaMarginTop}` : 'mt-3',
			props.model.ctaMarginBottom !== 'default' ? `mb-${props.model.ctaMarginBottom}` : '',
		];
	});

	function ctaClasses(btn: ICta) {
		const btnClassesArr = [];

		if (btn.type) {
			btnClassesArr.push('btn--' + btn.type);
		}

		if (btn.size) {
			btnClassesArr.push('btn--' + btn.size);
		}

		if (btn.color) {
			btnClassesArr.push('btn--' + btn.color);
		}

		const btnClasses = btnClassesArr.join(' ');
		return btnClasses;
	}

	const promotionData = computed(() => {
		if (props.model.richText && props.model.trackAsPromotion) {
			return gtmTrackSelectPromotion(
				'paragraph promotion',
				props.model.contentType,
				handleGtmHeader(props.model.richText, 'missing promotion name')
			);
		}
		return null;
	});

	const handleViewPromotionTracking = () => {
		const { trackAsPromotion, contentType, richText } = props.model;

		if (!trackAsPromotion || !dataLayer || !richText) return;

		gtmTrackViewPromotion('paragraph promotion', contentType, handleGtmHeader(richText, 'missing promotion name'));
	};

	onMounted(() => {
		handleViewPromotionTracking();
	});

	onUpdated(() => {
		handleViewPromotionTracking();
	});
</script>

<style lang="scss">
	@import './ParagraphBlock.module.scss';
</style>
