<template>
	<div :class="['tile-selectable-button', { 'center-text': model.btnCenter }]">
		<smart-link
			:url="gotoUrl()"
			:class="ctaClasses()"
			:target="model.target"
			:title="model.title"
			:gtmLabel="model.gtmLabel"
			:gtmAction="model.gtmAction"
			:useVueRoute="model.link.useVueRoute"
			@click="trackBasketCta"
		>
			{{ model.title }}
			<smart-svg
				v-if="model.target === '_blank'"
				class="footer__nav-link-icon"
				:url="IconLinkExternal"
			>
			</smart-svg>
		</smart-link>
	</div>
</template>

<script setup lang="ts">
	import {
		TrackProductItem,
		dataLayer,
		getTrackCategory2,
		getTrackCategory3,
	} from '@/components/utilities/trackingUtility';
	import IconLinkExternal from '@/assets/svg/icon_system_external_link.svg';
	import { ITileSelectableButtonBlock } from '@/types/block';
	import { SelectableContainerStore } from '@/stores/SelectableContainer';

	const selectableGroupIdTrackId = 'basket - ab.dk';
	const selectableGroupIdName = 'basket - ab.dk';

	const selectableStore = SelectableContainerStore();

	const props = defineProps<{
		model: ITileSelectableButtonBlock;
		storybook?: boolean;
	}>();

	const ctaClasses = (): string => {
		const btnClassesArr = [];

		if (props.model.btnType) {
			btnClassesArr.push('btn--' + props.model.btnType);
		}

		if (props.model.btnSize) {
			btnClassesArr.push('btn--' + props.model.btnSize);
		}

		if (props.model.btnColor) {
			if (hasSelectables()) {
				btnClassesArr.push('btn--' + props.model.btnColor);
			} else {
				btnClassesArr.push('btn--inactive');
			}
		}

		const btnClasses = btnClassesArr.join(' ');
		return 'btn ' + btnClasses;
	};

	const gotoUrl = (): string => {
		if (hasSelectables()) {
			// added '|| []' since VC cannot deduct selectables is never undefined
			const selectables = selectableStore.selectableContainers.get(props.model.selectableGroupId) || [];
			const preQuery = props.model.preQuery || '';
			return props.model.link?.url + preQuery + selectables.join();
		}
		// inactive
		return '#';
	};

	const hasSelectables = (): boolean => {
		const selectables = selectableStore.selectableContainers.get(props.model.selectableGroupId);
		return !!(selectables && selectables.length > 0);
	};

	const trackBasketCta = (): void => {
		const selectables = selectableStore.selectableContainers.get(props.model.selectableGroupId);
		if (!selectables) {
			return;
		}

		const items: TrackProductItem[] = [];
		selectables.forEach((product) => {
			items.push({
				item_id: product,
				item_name: product,
				affiliation: 'online',
				coupon: 'one insurance - 15% discount',
				discount: 0,
				index: 0,
				item_brand: 'alm. brand',
				item_category: 'insurance',
				item_category2: getTrackCategory2(product),
				item_category3: getTrackCategory3(product),
				item_variant: 'basic coverage',
				item_list_id: selectableGroupIdTrackId,
				item_list_name: selectableGroupIdName,
				price: 0,
				quantity: 1,
			});
		});

		dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
		dataLayer.push({
			event: 'add_to_cart',
			item_list_id: selectableGroupIdTrackId,
			item_list_name: selectableGroupIdName,
			ecommerce: {
				currency: 'DKK',
				value: 0,
				items,
			},
		});

		dataLayer.push({
			event: 'trackInsuranceSelectionInitiated',
			insuranceSelectionType: selectables.sort((a, b) => a.localeCompare(b)),
		});
	};
</script>

<style lang="scss" scoped>
	@import './TileSelectableButtonBlock.module.scss';
</style>
