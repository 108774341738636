<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="accordion"
		:class="[model.backgroundColor?.cssClass, { 'accordion--padding': model.padding }]"
	>
		<div
			v-if="model.header"
			class="accordion__header rich-text"
			v-html="model.header"
		></div>
		<ul
			v-if="model.accordionsArea?.groups"
			class="accordion__tabs"
		>
			<li
				v-for="(group, i) in model.accordionsArea?.groups"
				:key="i"
				class="accordion__tabs-item"
			>
				<button
					class="accordion__tabs-button"
					:class="{ 'accordion__tabs-button--active': i === activeGroupIndex }"
					type="button"
					@click="setActiveGroupIndex(i)"
				>
					{{ group.title }}
				</button>
			</li>
		</ul>
		<div class="accordion__list">
			<batgirl-accordion-item-block
				v-for="(item, i) in activeGroupItems"
				:key="`${activeGroupIndex}_${i}`"
				:model="item"
				:class="model.itemsBackgroundColor?.cssClass"
				:usabilla="{
					showUsabilla: model.showUsabillaInPage,
					scriptId: model.usabillaInPageScriptId,
					widgetId: model.usabillaInPageWidgetId,
				}"
			>
			</batgirl-accordion-item-block>
			<batgirl-accordion-item-block
				v-for="(item, i) in model.accordionsArea?.items"
				:key="i"
				:model="item"
				:class="model.itemsBackgroundColor?.cssClass"
				:usabilla="{
					showUsabilla: model.showUsabillaInPage,
					scriptId: model.usabillaInPageScriptId,
					widgetId: model.usabillaInPageWidgetId,
				}"
			></batgirl-accordion-item-block>
		</div>
		<div
			v-if="model.ctaBlock"
			class="accordion__cta"
		>
			<smart-link
				class="btn"
				:class="getButtonClasses"
				:url="getUrl"
				:target="model.ctaBlock.target"
				:gtmLabel="model.ctaBlock.gtmLabel"
				:gtmAction="model.ctaBlock.gtmAction"
				v-text="model.ctaBlock.title"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, ref, onMounted } from 'vue';
	import { IAccordionContainerBlock } from '@/types/block';
	import { BatgirlAccordionItemBlock } from '../index';

	const props = defineProps<{
		model: IAccordionContainerBlock;
	}>();

	const getUrl = computed(() => {
		if (props.model.ctaBlock?.link?.url) {
			return props.model.ctaBlock.link.url;
		}
		return '';
	});

	const getGroupNames = computed(() => {
		return (
			((props.model.accordionsArea as any).groups && (props.model.accordionsArea as any).groups.length > 1) ||
			false
		);
	});

	const getHasUsabillaWidget = computed(() => {
		return props.model.showUsabillaInPage && props.model.usabillaBlock.usabillaInPageWidgetId;
	});

	const getShowUsabillaInPageWidget = computed(() => {
		return (
			props.model.showUsabillaInPage &&
			!!props.model.usabillaInPageWidgetId &&
			!!props.model.usabillaInPageScriptId
		);
	});

	const getButtonClasses = computed(() => {
		if (props.model.ctaBlock) {
			const cta = props.model.ctaBlock;
			const size = cta.btnSize ? cta.btnSize : '';
			const type = cta.btnType ? cta.btnType : '';
			const color = cta.btnColor ? cta.btnColor : '';
			return `btn--${size} btn--${type} btn--${color}`;
		} else {
			return '';
		}
	});

	onMounted(() => {
		if (getHasUsabillaWidget.value && (window as any).usabilla) {
			// console.log("%cFound Usabilla in window", "color:green; font-size: 30px; font-weight:800;");
			(<any>window).usabilla.load('w.usabilla.com', props.model.usabillaInPageScriptId);
		}

		if (!getGroupNames.value && getShowUsabillaInPageWidget && props.model.usabillaInPageScriptId) {
			const usabillaScriptId = props.model.usabillaInPageScriptId;
			initUsabilla(usabillaScriptId);
		}
	});

	// TODO: on group change
	// BLOCKED (activeGroupIndex already defined as 0? below)
	// const activeGroupIndex = computed(() => {
	//     return props.model.accordionsArea && props.model.accordionsArea.groups ? props.model.accordionsArea.groups.findIndex(group => (group as any).isActive) : null;
	// })

	// new version
	// watch(activeGroupIndex, (value, oldValue) => {
	//     // lazy init usabilla inpage widgets dynamically on group change
	//     if (oldValue !== null && getShowUsabillaInPageWidget && props.model.usabillaInPageScriptId) {
	//         initUsabilla(props.model.usabillaInPageScriptId, 300);
	//     }
	// })

	// Old solution (from epi solution (vue2))
	// @Watch('activeGroupIndex')
	// onPropertyChanged(value: any, oldValue: any) {
	//     if (this.categoryQuery) {
	//         this.findIndexOfTitle(this.categoryQuery);
	//         window.history.replaceState({}, document.title, this.url); // reset url
	//     }

	//     if (value === -1 && this.startCategory !== -1 && this.startCategory) {// select group with url param
	//         this.setActiveGroup(this.startCategory);
	//     } else if (value === -1) {// select first group if none selected
	//         this.setActiveGroup(0);
	//     }

	//     // lazy init usabilla inpage widgets dynamically on group change
	//     if (oldValue !== null && getShowUsabillaInPageWidget) {
	//         this.initUsabilla(this.model.usabillaInPageScriptId, 300);
	//     }
	// }

	function initUsabilla(scriptId: string, delay: number = 0) {
		setTimeout(() => {
			(<any>window).usabilla.load('w.usabilla.com', scriptId);
		}, delay);
	}

	const activeGroupIndex = ref(0);

	const activeGroupItems = computed(() => {
		const activeGroup = props.model.accordionsArea?.groups[activeGroupIndex.value];
		if (!activeGroup) {
			return [];
		}
		return activeGroup.accordionItems.items.map((item) => item.content);
	});

	const setActiveGroupIndex = (i: number) => {
		activeGroupIndex.value = i;
	};
</script>

<style lang="scss">
	@import './BatgirlAccordionContainerBlock.module.scss';
</style>
